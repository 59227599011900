const __request = require(`./__request/__request_contentType_json`)
const request = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/web/company/saveCompanyInvoice',
        data: data
    }
    return __request(pParameter)
}
/**保存发票 */
export default request