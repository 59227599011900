export default {
  data() {
    return {
      bankSelect: {}
    }
  },
  watch: {

  },
  methods: {
    getBankSelect(name) {
      // console.log(this.bankSelect)
      // this.formData[name] = this.bankSelect.value.bankName
      this.$refs['formData'].validateField('openingBank')
    },
    arrangeBankSelect(data) {
      let _data = {}
      _data.openingBank = data.openingBank.id
      _data.openingBankName = data.openingBank.bankName
      data = Object.assign(data, _data)
      return data
    }
  },
  mounted() {
    // let bankSelect = this.$refs.blankSelect.$refs.aBaseEntitySelector
    // this.bankSelect = bankSelect
  },
}