import CardBox from '@/component/cardBox'
import DialogBox from '@/component/dialog'
import consumer_web_company_saveCompanyInvoice from '@/lib/data-service/haolv-default/consumer_web_company_saveCompanyInvoice'
import consumer_web_company_getCompanyInvoiceList from '@/lib/data-service/haolv-default/consumer_web_company_getCompanyInvoiceList'
import consumer_web_company_delCompanyInvoice from '@/lib/data-service/haolv-default/consumer_web_company_delCompanyInvoice'
import consumer_web_company_updateCompanyInvoice from '@/lib/data-service/haolv-default/consumer_web_company_updateCompanyInvoice'
import { verifyPhone, verifySpace, verifyPhoneOrLandline } from '@/lib/utils/verify'
import BlankSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/blank-selector/1.0.0/index.vue';
import bankSelect from '../components/bankSelect.js';

export default {
    name: "invoice",
    data() {
        return {
            invoiceList: [],
            cardText: [
                {
                    title: "发票抬头：",
                    parameter: "invoiceTitle"
                },
                {
                    title: "纳税人识别号：",
                    parameter: "taxpayerNo"
                }
            ],
            editShow: false,
            dialogType: '',
            formData: {
            },
            rules: {
                invoiceTitle: [
                    { required: true, message: '请输入发票抬头', trigger: 'blur' },
                    { validator: verifySpace }

                ],
                taxpayerNo: [
                    { required: true, message: '请输入纳税人识别号', trigger: 'blur' },
                    { validator: verifySpace }
                ],
                address: [
                    { required: true, message: '请输入地址', trigger: 'blur' },
                    { validator: verifySpace }
                ],
                phone: [
                    { required: true, message: '请输入电话', trigger: 'blur' },
                    { validator: verifyPhoneOrLandline }
                ],
                openingBank: [
                    { required: true, message: '请输入开户行', trigger: 'blur' },
                ],
                bankAccount: [
                    { required: true, message: '请输入账户', trigger: 'blur' },
                    { validator: verifySpace }
                ],
            }
        };
    },
    component: {

    },
    components: { CardBox, DialogBox, BlankSelector },
    created() {
        this.getInvoiceList()
    },
    watch: {

    },
    methods: {
        editCard(val) {
            this.formData = Object.assign({}, val)
            console.log(this.formData)
            this.editShow = true
            this.$refs['formData'].resetFields();
            this.dialogType = 'edit'
        },
        cancel(val) {
            this.formData = {}
            this.editShow = val
        },
        selectDefault(val) {
            consumer_web_company_updateCompanyInvoice(val).then(res => {
                this.getInvoiceList()
            })
        },
        editInvoiceList(data) {
            this.arrangeBankSelect(data)
            consumer_web_company_updateCompanyInvoice(data).then(res => {
                this.editShow = false
                this.getInvoiceList()
                this.$message({ type: "success", message: "修改成功！" })
            })
        },
        addCard() {
            this.editShow = true
            this.$refs['formData'].resetFields();
            this.formData = {}
            this.dialogType = 'add'
        },
        deleteCard(val) {
            this.$confirm('确定要删除操作吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.delInvoiceList({ id: val.id })
            }).catch(() => {

            });
        },
        getInvoiceList() {
            let data = {
                searchCriteria: this.formData.searchCriteria || ''
            }
            consumer_web_company_getCompanyInvoiceList(data).then(res => {
                this.invoiceList = res.datas
                this.arrangeBankSelect(this.invoiceList)
            })
        },
        setInvoiceList(self) {
            let _this = self.$parent
            _this.$refs['formData'].validate(valid => {
                if (valid) {
                    let data = _this.formData
                    try {
                        if (_this.dialogType === 'add') {
                            _this.addInvoiceList(data)
                        } else {
                            _this.editInvoiceList(data)
                        }
                    } catch{ }
                } else {
                    return false
                }
            })
        },
        addInvoiceList(data) {
            this.arrangeBankSelect(data)
            consumer_web_company_saveCompanyInvoice(data).then(res => {
                this.editShow = false
                this.getInvoiceList()
                this.$message({ type: "success", message: "添加成功！" })
            })
        },
        delInvoiceList(data) {
            consumer_web_company_delCompanyInvoice(data).then(res => {
                console.log(res)
                this.editShow = false
                this.getInvoiceList()
                this.$message({ type: "success", message: "删除成功！" })
            })
        },

    },
    mixins: [bankSelect]
};
